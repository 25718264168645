.img-tiktiki{
  text-align: center;
}
.img-tiktiki img{
  width: auto;
  // height: 65px !important;
  margin-top: 20px;
  width: 80px;
}
.chain-section {
  padding: 60px 0 60px 0;
}
.uread-bold-message {
    font-weight: bold;
    font-size: 16px !important;
}

.uread-message-badge {
    font-weight: bold;
    font-size: 11px !important;
    color: rgb(255, 255, 255);
    background-color: #ea4a69;
    border: 1px solid #ea4a69;
    border-radius: 100px;
    display: inline-block;
    padding: 2px;
    margin: 1px;
    width: 25px;
    height: 25px;
    text-align: center;
}

.follow-font-bold {
    font-weight: bold;
}
.password-icon-area {
    position: relative;
    button {
        position: absolute;
        right: 10px;
        top: 55%;
        transform: translateY(-50%);
        background: none;
        border: none;
        cursor: pointer;
    }

    .anticon svg {
        width: 20px;
        height: 20px;
    }
}
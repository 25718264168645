.font-16 {
    font-size: 16px;
  font-weight: 700;
  color: #212121;
  margin: 0;
}

p.font-11-thin {
    font-size: 11px;
    margin: 0;
    color: #262626;
}

.theme-text-color {
    color: #1DBF73 !important; 
    
}

p.font-12 {
    font-size: 12px;
    margin-bottom: 0;
    color: #262626;
}

.font-20 {
    font-size: 20px;
    font-weight: 700;
}

.tags-title {
    sup {
        color: #FF0000;
    }

    span {
        font-weight: 300;
    }
}
body {
	top: 0 !important;
}
body > .skiptranslate {
	display: none;
}
.goog-logo-link {
	display: none !important;
}

.goog-te-gadget {
	color: transparent !important;
	height: 24px;
	overflow: hidden;
}
.goog-te-gadget .goog-te-combo {
	color: #000 !important;
	border: 1px solid darkgray;
}
.header-quick-menu{
	background-color: transparent;
	padding: 0px 45px 0px 45px;
    margin: 0 auto;
}
.header-quick-menu .ant-menu-horizontal {
	background-color: transparent;
	border-bottom: none;
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}
.header-quick-menu .ant-menu-item{
	border-bottom: none;
	font-size:20px;
	font-weight:700;
	padding-left:0px;
	padding-right:0px;
}

.header-quick-menu .ant-menu-item a{
 color: #000;
}
.header-quick-menu .ant-menu-item:hover{
	border-bottom: none;
}
.header-quick-menu .ant-menu-item a:hover{
	color: #1dbf74;
}
.header-quick-menu .ant-menu-item a.active{
	color: #1dbf74;
}
.header-quick-menu .ant-menu-item a img{
	width: 60%;
}
.ant-menu-horizontal>.ant-menu-item-active, .ant-menu-horizontal>.ant-menu-item-open, .ant-menu-horizontal>.ant-menu-item-selected, .ant-menu-horizontal>.ant-menu-item:hover, .ant-menu-horizontal>.ant-menu-submenu-active, .ant-menu-horizontal>.ant-menu-submenu-open, .ant-menu-horizontal>.ant-menu-submenu-selected, .ant-menu-horizontal>.ant-menu-submenu:hover {
    color: #1dbf73;
    border-bottom: 2px solid #1dbf73;
}
.header-quick-menu .ant-menu-submenu-title{
	font-size: 35px;
	font-weight: 600;
}

#google_translate_element{
	width: 78%;
	border: 1px solid #0b132b;
	padding: 9px;
	border-radius: 30px;
	display: inline-block;
}
.goog-te-gadget .goog-te-combo {
	margin: 0 !important;
}

.language .goog-te-gadget .goog-te-combo{
	border: none !important;
	font-family: 'Roboto', sans-serif;
	font-size: 20px;
	outline: none;
	font-weight: bold;
	width: 100%;
	cursor: pointer;
}
.ant-form-item-label {
	text-align: left !important;
}

.ant-drawer-footer .ant-btn-primary{
 margin-left: 20px;
 border: none;
 border-radius: 30px;
 background-color: #1dbf73;
}
// .header-user-profile.mobile{
// 	display: none;
// 	opacity: 0;
// 	visibility: hidden;
// }


.mobileMenuBar{
	.mobileBar{
		cursor: pointer;
		span{
			height: 3px;
			background: #1dbf73;
			width: 25px;
			display: block;
			margin-bottom: 5px;
			border-radius: 2px;
			// &:nth-child(2){
			// 	width: 20px;
			// 	margin-left: auto;
			// }
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
}
.main-menulist,
.header-signin-btn{
	margin-left: auto;
}
.mobileOthersBar.small,
.mobileOthersBar,
.close,
.mobileBar,
.mobileMenuBar .mobileBar{
	display: none!important;
	visibility: hidden;
	opacity: 0;
}
.header-user-profile.mobile {
    margin-left: 20px;
}
.menuWrapper{
	width: calc(100% - 300px);
	display: flex;
    align-items: center;
    justify-content: center;
}

.company-logo a {
    display: block;
}
.dummyMobleLanguage{
	display: none;
	visibility: hidden;
	opacity: 0;
}



@media screen and (min-width: 1201px) and (max-width: 1680px) {
	.header-quick-menu {
		background-color: transparent;
		padding: 0 20px;
		margin: 0 auto;
	}
	.listing-page-container {
		padding: 0px 10px 50px 10px !important;
	}
	.sidebar-left-content .listing-page-left-sidebar {
		background-color: #ffffff;
		padding: 15px 7px !important;
	}
	.listing-page-container .card-item {
		padding: 15px!important;
	}
	.card-item-header .company-name {
		font-size: 13px !important;
	}
	.card-item .follow span {
		font-size: 12px;
		padding: 3px 12px !important;
	}

	.card-item-footer button {
		padding: 12px 20px !important;
		font-size: 15px !important;
	}

}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
	.header-quick-menu {
		background-color: transparent;
		padding:  0 20px;
		margin: 0 auto;
	}

	.sidebar-left-content .listing-page-left-sidebar {
		background-color: #ffffff;
		padding: 15px 7px !important;
	}
	.listing-page-container .card-item {
		padding: 15px !important;
	}
	.card-item-header .company-name {
		font-size: 13px !important;
	}
	.card-item .follow span {
		font-size: 12px;
		padding: 3px 12px !important;
	}

	.card-item-footer button {
		padding: 12px 20px !important;
		font-size: 15px !important;
	}
	.header-quick-menu .ant-menu-item a {
		padding-right: 10px !important;
		font-size: 18px !important;
	}
	.group-logo-resize img{
		width: 55px;
	}
	.header-quick-menu .group-logo {
		padding-right: 18px !important;
		margin-top: 0px !important;
	}

}

@media screen and (max-width: 1024px){
	.header-quick-menu {
		padding: 0 50px;
	}
	.header-quick-menu .ant-menu-item a {
		padding: 0px 23px;
	}
}
@media screen and (max-width: 1023px){
	.header-quick-menu{
		padding: 0px 50px;
	}
}
@media screen and (max-width: 991px){
	.dummyMobleLanguage .es__indicator{
		padding: 8px 1px 8px 0px;
	}
	.dummyMobleLanguage .es__menuPortal,
	.dummyMobleLanguage .es__menu{
		color: #000;
	}
	.dummyMobleLanguage .es__indicator-separator{
		display: none;
		opacity: 0;
		visibility: hidden;
	}
	.dummyMobleLanguage .es__control{
		border: 1px solid #eee;
    	border-radius: 4px;
	}
	.dummyMobleLanguage{
		display: block;
		visibility: visible;
		opacity: 1;
		width: 120px;
		font-size: 14px;
	}
	.company-logo a img {
		width: 200px;
		height: auto;
	}
	.menuWrapper .header-user-profile{
		display: none;
		opacity: 0;
		visibility: hidden;
	}
	.mobileOthersBar.small .header-right.mobile{
		display: none!important;
		opacity: 0;
		visibility: hidden;
	}
	.header-right.mobile{
		margin-left: 0;
	}
	
	.header-right{
		display: none!important;
		opacity: 0;
		visibility: hidden;
	}
	.mobileOthersBar{
		width: calc(100% - 300px);
		display: flex!important;
		align-items: center;
		visibility: visible;
		opacity: 1;
		justify-content: center;
		.header-user-profile.mobile,
		.header-right.mobile{
			display: flex!important;
			opacity: 1;
			visibility: visible;
		}
	}
	
	.language{
		margin-left: 0!important;
		width: 100%!important;
		max-width: 100%!important;
	}
	.menuWrapper{
		width: calc(100% - 245px);
	}
	.close,
	.mobileMenuBar{
		display: block!important;
		visibility: visible;
		opacity: 1;
	}
	.mobileMenuBar{
		display: flex !important;
		align-items: center;
	}
	.mobileMenuBar .mobileBar{
		margin-left: 15px;
		display: block !important;
		opacity: 1;
		visibility: visible;
	}
	.header-signin-btn a{
		padding: 6px 25px;
	}
	
	.menuWrapper.sidebarOpne{
		left: 0px;
	}
	.menuWrapper {
		position: fixed;
		left: -300px;
		top: 0;
		width: 300px;
		background: #fff;
		height: 100vh;
		z-index: 999;
		display: block;
		transition: .3s ease;
	}
	.close{
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.closeWrapper{
		cursor: pointer;
		height: 25px;
		width: 25px;
		margin-right: 10px;
		margin-top: 10px;
		margin-left: auto;
	}
	.closeWrapper span:last-child{
		transform: rotate(-45deg);
		transform-origin: center center;
		margin-top: -2px;
	}
	.closeWrapper span:first-child{
		transform: rotate(45deg);
		transform-origin: center center;
		margin-top: 2px;
	}
	.closeWrapper span{
		display: block;
		height: 2px;
		background: #000;
		width: 20px;
		transform-origin: center center;
	}
}

.ant-popover.top-bar-notification.cbNotification.ant-popover-placement-bottomRight{
	position: fixed;
}

@media screen and (max-width: 768px){
	.mobileMenuBar .mobileBar span{
		margin-bottom: 5px;
	}
	
}
@media screen and (max-width: 639px){
	
	.mobileMenuBar .mobileBar,
	.mobileOthersBar{
		display: none!important;
		opacity: 0;
		visibility: hidden;
	}
	.mobileOthersBar.small .mobileMenuBar,
	.mobileOthersBar.small .header-right.mobile{
		display: block!important;
		opacity: 1;
		visibility: visible;

	}
	.mobileOthersBar.small{
		display: flex!important;
		opacity: 1;
		visibility: visible;
		width: 100%;
		padding-bottom: 15px;
	}
	.header-pagecontent{
		display: block!important; 
	}	
	.header-pagecontentbody{
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.header-right.mobile{
		margin-left: 0;
	}	
	.mobileBar.forMobile {
		display: block!important;
		opacity: 1;
		visibility: visible;
		margin-left: 0;
	}
	.mobileOthersBar.small{
		display: flex;
		align-items: center;
		.header-right.mobile{
			// width: calc(100% - 40px);
			display: flex!important;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			margin-top: 20px;
		}
	}
}


@media screen and (max-width: 480px){
	.mobileMenuBar .mobileBar span{
		margin-bottom: 5px;
	}
	// .withOutIsAathforMobile{
	// 	display: none;
	// 	opacity: 0;
	// 	visibility: hidden;
	// }
	
}
@media screen and (max-width: 425px) {
	.header-quick-menu {
		padding: 0px 0px;
	}
	.mobileMenuBar .mobileBar span{
		margin-bottom: 4px;
	}
	.company-logo a img{
		width: 150px;
	}
	.dummyMobleLanguage .es__indicators,
	.dummyMobleLanguage .es__control.es__control--is-focused, 
	.dummyMobleLanguage .es__control {
		min-height: 30px;
		height: 30px;
		max-height: 30px;
	}
	.mobileBar.forMobile span {
		width: 20px;
	}
}



@media screen and (max-width: 425px) {
	.card-item-footer button{
		font-size: 14px;
	  }
	.card-item-header{
	  display: block!important;
	}
	.header-quick-menu .ant-menu-item a{
		font-size: 15px;
		padding: 0 15px;
	}
  }

@media screen and (max-width: 375px) {
	.card-item-footer button{
		padding: 12px 35px!important;
	}
}
@media screen and (max-width: 375px) {
	.header-quick-menu {
		padding: 0px 0px;
	}
	.header-quick-menu .ant-menu-item:first-child a{
		padding-left: 0;
	}
	.header-quick-menu .ant-menu-item a {
		font-size: 15px;
		padding: 0 17px;
	}
	.company-logo a img{
		width: 120px;
	}
	.dummyMobleLanguage{
		width: 100px;
	}
}

@media screen and (max-width: 320px) {
	.header-quick-menu {
		padding: 0px 0px;
	}
	.company-logo a img{
		width: 100px;
	}
}



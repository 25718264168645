body{
  background-color: #ececec;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
a{
  text-decoration: none;
}

.buyerSLider{
  width: 50%;

}

.header-pagecontent {
  background-color: #ffffff;
  min-height: 75px;
  padding: 0 50px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  align-content: center;
  justify-content: space-between;
  width: 100%;
}

.header-pagecontentbody{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.full-pageheader .header-pagecontentbody{
  max-width: 100%;
}

.adminloginpage-wrapper .header-pagecontent {
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
}



/*start:header */

.main-menulist ul {
  margin: 0;
  padding: 0;
}
.main-menulist li {
  margin: 0;
  padding: 0 10px;
  list-style: none;
  display: inline-block;
  vertical-align: middle;
}
.main-menulist li a {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  border: 1px solid #000000;
  border-radius: 30px;
  padding: 6px 20px;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.main-menulist li a.active {
  border: 1px solid #1dbf74;
  border-radius: 30px;
}
.main-menulist li a:hover {
  border: 1px solid #1dbf74;
  border-radius: 30px;
}

.headerSampleOne{
  position: absolute;
  right:  0;
  top: 35px;
}
/*end:header */



/*start:admin login*/
.admin-loginpage {
  padding: 0px 0 40px 0;
}

.admin-login-box {
  max-width: 550px;
  margin: 0 auto;
  background-color: #fff;
  padding: 50px;
  box-shadow: 0 0 5px #888;
}
.admin-loginpage:after {
  content: '';
  width: 100%;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  //background-image: url(../../../../assets/images/shutterstock-1414654763.jpg);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  z-index: -1;
}
.adminlogin-logo {
  text-align: center;
  padding: 0 0 40px 0;
}

.row-admin-login {
  position: relative;
  display: flex;
}
.row-admin-login .admin-login-input-field {
  display: block;
  position: relative;
}
.row-admin-login input[type="text"], .row-admin-login input[type="email"], .row-admin-login input[type="password"] {
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: 700;
  background-color: #fff;
  color: #777;
  line-height: 20px;
  text-align: left;
  margin: 0;
  padding: 0 20px;
  outline: none;
  border: 1px solid #b6b6b6;
  border-radius: 30px;
}

.row-admin-login.admin-login-submit-btn {
  text-align: center;
  padding-top: 5px;
}

.row-admin-login + .row-admin-login {
  margin-top: 20px;
}
.registration-completed-successfully .admin-create-newaccountlink a {
  width: 300px;
  height: auto;
  font-size: 20px;
  font-weight: 700;
  background-color: #1dbf74;
  color: #ffffff;
  line-height: 20px;
  text-align: center;
  margin: 0;
  padding: 13px 0;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease 0s;
  text-transform: none;
  border-radius: 30px;
  display: block;
  text-decoration: none;
}
.registration-completed-successfully .admin-create-newaccountlink a:hover{
  background-color: #333;
  color: #ffffff;
}
.registration-completed-successfully h3, .registration-completed-successfully p {
  text-align: center;
}
.registration-completed-successfully .createaccount-contentbody{
  padding-bottom: 40px;
}

.admin-backlink a {
  width: auto;
  height: auto;
  font-size: 20px;
  font-weight: 700;
  background-color: #333;
  color: #ffffff;
  line-height: 20px;
  text-align: center;
  margin: 0;
  padding: 13px 0;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease 0s;
  text-transform: none;
  border-radius: 30px;
  display: block;
  text-decoration: none;
}
.admin-backlink a:hover {
  background-color: #333;
  color: #ffffff;
}
.forgot-password-admin  .admin-backlink a{
  color: #ffffff;
  text-decoration: none;
}
.forgot-password-admin  .admin-backlink a:hover{
  color: #ffffff;
  text-decoration: none;
}
.forgot-password-admin  .admin-backlink{
  display: block;
  width: 100%;
}

/*start:*/
.createaccountpage.confirm-email-page {
  text-align: center;
}
.createaccountpage.confirm-email-page  .createaccount-contentbody {
  padding: 40px;
}
.createaccountpage.confirm-email-page   .admin-backlink a{
  background-color: #1dbf74;
}
.createaccountpage.confirm-email-page   .admin-backlink a:hover{
  background-color: #063247;
}
/*end:*/

/*start:*/
.createaccountpage.password-reset-admin {
  text-align: center;
}
.createaccountpage.password-reset-admin .createaccount-contentbody {
  padding: 40px;
  max-width: 500px;
}
.createaccountpage.password-reset-admin   .admin-backlink a{
  background-color: #1dbf74;
}
.createaccountpage.password-reset-admin   .admin-backlink a:hover{
  background-color: #063247;
}
/*end:*/
.admin-loginpage.resetpassword-successview {
  text-align: center;
}
.admin-loginpage.resetpassword-successview  span.admin-create-newaccountlink {
  display: block;
  width: 100%;
}
.admin-loginpage.resetpassword-successview .admin-create-newaccountlink a{
  width: 100%;
  height: auto;
  display: block;
  font-size: 20px;
  font-weight: 700;
  background-color: #1dbf74;
  color: #fff !important;
  line-height: 20px;
  text-align: center;
  margin: 0;
  padding: 13px 0;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all .5s ease 0s;
  text-transform: none;
  border-radius: 30px;
  text-decoration: none !important;
}
.admin-loginpage.resetpassword-successview .admin-create-newaccountlink a:hover{
  background-color: #063247;
}


.reset-yourpassword-admin  .admin-backlink{
  display: block;
  width: 100%;
}
.reset-yourpassword-admin  .admin-backlink a{
  color: #ffffff;
  text-decoration: none;
}
.reset-yourpassword-admin  .admin-backlink a:hover{
  color: #ffffff;
  text-decoration: none;
}


.row-admin-extratext {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  align-items: center;
  justify-content: center;
}
.admin-ortext{
  color: #777;
  padding: 0 5px;
}
.row-admin-extratext a{
  text-decoration: underline;
  color: #777;
  transition: all 0.5s ease 0s;
}

.row-admin-extratext a:hover{
  color: #0773b1;
}

.admin-create-newaccountlink a{
  color: #1dbf74;

}

.admin-create-newaccountlink a:hover{
  color: #0773b1;
}

/*end:admin login*/

.follow-us{
  padding: 5px 0 5px 0;
  text-align: center;
}

.follow-us a + a{
  margin-left: 30px;
}

.follow-us h4{
  color: #777;
  text-align: center;
  font-size: 21px;
  font-weight: 700;
  margin: 0;
  padding: 0 0 15px 0;
}

.copyright-textmessage{
  padding: 0 0 20px 0;
}
.copyright-textmessage p{
  color: #777;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  margin: 0;
  padding: 0;
}
.copyright-textmessage p + p{
  font-size: 14px;
}

.footer-pagecontent{
  position: relative;
}
//.footer-pagecontent:after {
//  content: '';
//  width: 16px;
//  height: 20px;
//  position: absolute;
//  right: 40px;
//  bottom: -30px;
//  background: url(../../../../assets/images/copyright-tag.png) no-repeat;
//}
.footer-pagecontent:after {
  content: '';
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 100px;
  right: 50%;
  background: url(../../../../assets/images/copyright.png) no-repeat;
  display: none !important;
}

.copyright-textmessage p{
  color: #777;
  padding-top: 10px;
  font-weight: 700;
  font-size: 13px;
}

/*start:big circle*/
.big-circleblock-contentbody{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 100px 0 0 0;
}
.big-circleblock{
  width: 145px;
  height: 145px;
  background: url(../../../../assets/images/Ellipse_2.png) no-repeat center center/cover;
  border-radius: 100%;
  margin-right: 30px;
  padding: 8px;
  box-shadow: 7px 7px 5px rgba(0, 0, 0, 0.25);
  margin-bottom: 30px;
  cursor: pointer;
}
.big-circleblock-inner{
  height: 100%;
  width: 100%;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all .4s ease;
}
.big-circleblock-inner:hover {
  background: #fff685;
}
.big-circleblock-inner p {
  margin: 0;
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
}
.big-circleblock-inner p a{
  color: #000000;
}
/*end:big circle*/


/*start:chain section*/
.chain-section {
  padding:60px 0 40px 0;
  text-align: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.chain-section-wrapper{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.chain-section .chain-item-block {
  padding-right: 30px;
  padding-left: 30px;
}
.chain-item-main {
  display: inline-block;
  text-align: center;
  max-width: 94px;
}
.chain-item-inner {
  display: inline-block;
  padding: 10px;
  border-radius: 100%;
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 0 10px 0;
}
.chain-item-inner::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 2px dashed #004cff;
  padding: 10px;
  border-radius: 100%;
  -webkit-animation: spinAround 15s linear infinite;
  -moz-animation: spinAround 15s linear infinite;
  animation: spinAround 15s linear infinite;
}

@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes spinAround {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chain-item-inner:after {
  content: "";
  position: absolute;
  width: 121px;
  height: 16px;
  border-top: 2px dashed #004cff;
  left: 83%;
  transform: rotate(-34deg);
  top: 43%;
}


.chain-item-thumb {
  width: 80px;
  height: 80px;
  background: url(../../../../assets/images/Ellipse_2.png) no-repeat center center/cover;
  border-radius: 100%;
  padding: 6px;
  box-shadow: 7px 7px 5px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
}
.chain-item-thumb a {
  display: block;
  position: relative;
  z-index: 1;
}
.chain-item-thumb::after {
  background: #fff685;
  position: absolute;
  content: "";
  left: 5px;
  top: 5px;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-radius: 50%;
  opacity: 0;
}
.chain-item-block h4:before {
  content: '';
  width: 25px;
  height: 25px;
  position: absolute;
  left: 0;
  top: 0;
  background: url(../../../../assets/images/blue-chain-icon.png) no-repeat;
}
.chain-item-block h4 {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  padding: 0 0 0 30px;
  position: relative;
}

.chain-item-inner:hover .chain-item-thumb::after {
  opacity: 1;
}
.chain-item.right .chain-item-inner:after {
  display: none;
}
.chain-item-thumb img {
  width: 70%;
  vertical-align: middle;
  border-style: none;
}


.chain-item-block.yarn .chain-item-inner:after {
  width: 95px;
    left: 87%;
    top: 47%;
    transform: rotate(
-37deg
);
}
.chain-item-block.textiles .chain-item-inner:after {
  width: 110px;
    left: 83%;
    top: 47%;
    transform: rotate(
-36deg
);
}
.chain-item-block.design  .chain-item-inner:after {
  width: 100px;
}
.chain-item-block.design .chain-item-inner:after {
  width: 130px;
  left: 82%;
  transform: rotate(-34deg);
}
.chain-item-block.consultant  .chain-item-inner:after {
  width: 130px;
  left: 82%;
  transform: rotate(-34deg);
}
.chain-item-block.consultant .chain-item-inner:after {
  width: 155px;
  left: 75%;
  transform: rotate(-32deg);
}
.chain-item-block.garment .chain-item-inner:after {
  width: 155px;
  left: 75%;
  transform: rotate(-32deg);
}
.chain-item-block.knowledge .chain-item-inner:after {
  width: 155px;
  left: 75%;
  transform: rotate(-32deg);
}
.chain-item-block.knowledge .chain-item-inner:after {
  width: 135px;
  left: 82%;
  transform: rotate(-32deg);
}

/*end:chain section*/



/*start:createaccount section*/
.createaccountpage-wrapper .header-pagecontent {
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
}
.createaccountpage{
  padding: 70px 0 0 0;
}
.createaccountpage:after {
  content: '';
  width: 100%;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  background-image: url(../../../../assets/images/shutterstock-1414654763.jpg);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  z-index: -1;
}
.createaccount-contentbody {
  background-color: rgba(255, 255, 255, 0.6);
  max-width: 440px;
  padding: 40px;
  margin: 0 auto;
  padding-bottom: 13px;
  margin-bottom: 30px;
}
.inputfieldof-createaccount {
  display: flex;
  position: relative;
}
.inputfieldof-createaccount {
  padding-top: 5px;
}
.inputfieldof-createaccount + .inputfieldof-createaccount {
  margin-top: 20px;
}
/*end:createaccount section*/



/*start:pagefiber*/
.pagefiber-pagecontent {
  padding: 60px 15px;
  display: flex;
}

.pagefiber-leftsidebar {
  width: 180px;
}
.pagefiber-mainsidebarcontentbody {
  width: calc(100% - 180px);
}
.round-navwrapper ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  margin: 0;
}

.round-navwrapper.cotton-fiber-nav ul li {
  width: 122px;
  height: 122px;
  margin-right: 0;
  margin-bottom: 20px;
  line-height: 119px;
  position: relative;
  background-image: url(../../../../assets/images/menuround.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  list-style:none;
}
.round-navwrapper.cotton-fiber-nav ul li a {
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
  height: 90%;
  color: #000;
  width: 90%;
  border-radius: 50%;
  margin: auto;
  z-index: 2;
  transition: all .3s ease;
  font-weight: bold;
  font-size: 17px;
}
.round-navwrapper ul li a:hover {
  background-color: yellow;
}

/*end:pagefiber*/

.pagefiber-mainsidebarcontentbody-inner {
  background-color: #fff;
  padding: 15px;
  margin-bottom:30px;
}
.pagefiber-mainsidebarcontentbody-breadcum {
  padding: 0 15px;
  margin: 0 0 20px 0;
  color: #777;
  font-size: 30px;
  font-weight: bold;
}
.pagefiber-contentbodyheading {
  padding: 0 0 50px 0;
  margin: 0;
  color: #000;
  font-size: 30px;
  font-weight: bold;
}

/*start:fiberform*/
.fiberform + .fiberform {
  margin-top: 50px;
}
.row-fiber {
  display: flex;
}
.col-fiber {
  padding-right: 10px;
  display: flex;
}
.col-fiber.refnumber-fiber {
  width: 10%;
}
.col-fiber.category-fiber {
  width: 20%;
}
.col-fiber.descriptioncol-fiber {
  width: 50%;
}
.col-fiber.qtycol-fiber {
  width: 10%;
}
.col-fiber.pricecol-fiber {
  width: 10%;
}

.row-fiber input[type="text"] {
  border: 1px solid #a0a0a0;
  height: 40px;
  border-radius: 5px;
  padding: 0 15px;
  width: 100%;
  line-height: 40px;
}
.row-fiber-heading .col-fiber {
  font-weight: 700;
}
.row-fiber + .row-fiber {
  margin-top: 10px;
}
.fiberform-subheading {
  padding: 0 0 15px 0;
  margin: 0;
  color: #000;
  font-size: 25px;
  font-weight: bold;
}
.row-fiber select {
  border: 1px solid #a0a0a0;
  height: 40px;
  border-radius: 5px;
  padding: 0 15px;
  width: 100%;
  line-height: 40px;
}

/*end:fiberform*/
.full-pageheader {
  position: relative;
  padding: 0 25px;
}
.full-pageheader .company-logo {
  left: 15px;
}
.full-pageheader .headerSampleOne{
  right: 15px;
}
.row-fibercotton input[type="text"] {
  padding: 0 6px;
  width: 100%;
  line-height: 40px;
  font-size: 12px;
}
.row-fibercotton select {
  padding: 0 2px;
  width: 100%;
  line-height: 40px;
  font-size: 12px;
}

.row-fibercotton .col-fiber {
  width: calc(100% / 11);
}

.savesubmitall-btn {
  padding: 30px 0 40px 0;
}
.savesubmitall-btn input[type="submit"] {
  border: none;
  border-radius: 5px;
  padding: 5px 15px;
  width: 100%;
  line-height: 40px;
  background-color: #1dbf73;
  height: auto;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.5s ease 0s;
}
.savesubmitall-btn input[type="submit"]:hover {
  background-color: #000;
}
.pageoffter-pagecontent {
  padding: 60px 15px;
}
.round-navwrapper-content{
  max-width: 1170px;
  margin: 0 auto;
}
.round-navwrapper-content ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.round-navwrapper-content ul li {
  width: 80px;
  height: 80px;
  position: relative;
  margin-right: 30px;
  background-image: url(../../../../assets/images/menuround.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
.round-navwrapper-content ul li a {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  text-align: center;
  height: 90%;
  color: #000;
  width: 90%;
  border-radius: 50%;
  margin: auto;
  z-index: 2;
  transition: all .3s ease;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
}

.round-navwrapper-content ul li.active a,
.round-navwrapper-content ul li a:hover {
  background-color: yellow;
}
.round-navwrapper-content ul li + li {
  margin-left: 25px;
}
.shopproduct-contentbody{
  max-width: 1170px;
  margin: 0 auto;
}
.shop-head {
  max-width: 1170px;
  margin: 0 auto;
  display: block;
  overflow: hidden;
  padding-bottom: 10px;
}
.shop-head-menu{
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.shop-head-menu li {
  color: #f3652a;
  display: inline-block;
  margin-right: 20px;
}
.shop-head-menu li a {
  color: #323333;
  text-decoration: none;
}
.shop-head-menu li span{
  color: #323333;
}
.shop-head-menu li a span {
  position: relative;
}
.shop-head-menu li i {
  color: #323333;
  display: inline-block;
  font-size: 14px;
  padding-right: 5px;
}

.shop-head ul.shop-head-menu li a span::after {
  color: rgb(185, 197, 209);
  content: "\f015";
  font-family: fontawesome;
  position: absolute;
  right: 0;
  top: 0;
}

.shophead-paddingtopbottom {
  padding: 40px 0 40px 0;
}

.navigation-rowline-contentbody {
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 30px 0 0 0;
}
.shoplink-fiber a {
  color: #000;
  border: 1px solid #1dbf74;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 17px;
  font-weight: bold;
  transition: all .3s ease;
  display: inline-block;
  vertical-align: top;
}
.shoplink-fiber a:hover,.shoplink-fiber a.active {
  background-color: #1dbf74;
  color: #fff;
}
.shoplink-fiber a + a {
  margin-left: 20px;
}

.uploadfiber-offerlink a {
  color: #fff;
  border: 1px solid #1dbf74;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 17px;
  font-weight: bold;
  transition: all .3s ease;
  display: inline-block;
  vertical-align: top;
  background-color: #1dbf74;
}

.navigation-rowline-contentbody ul.shop-head-menu {
  position: relative;
  top: 12px;
}

.countryoforigin-block {
  width: 200px;
}
.countryoforigin-block select {
  border: 1px solid #a0a0a0;
  height: 44px;
  border-radius: 5px;
  padding: 0 15px;
  width: 100%;
  line-height: 40px;
}


.offers-select {
  margin-top: 30px;
}
.offers-select  ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.offers-select-list li {
  display: inline-block;
  margin-right: 20px;
}

.coumtry-production select {
  border: none;
  background-color: transparent;
  color: #444;
  background-color: #fff;
  padding: 8px 15px;
  border-radius: 3px;
}

.product-tab-area {
  padding: 50px 0 0 0;
}
.product-tab-area img {
  width: 100%;
  height: auto;
}
.row-form{
  margin-bottom: 15px;
}
.row-form input[type="text"],.row-form input[type="tel"] {
  border: 1px solid #a0a0a0;
  height: 40px;
  border-radius: 5px;
  padding: 0 15px;
  width: 100%;
  line-height: 40px;
}
.row-form label{
  font-weight: bold;
  font-size: 17px;
  display: block;
}
.row-formgroup .row-form + .row-form {
  padding-left: 20px;
}

.row-form textarea {
  border: 1px solid #a0a0a0;
  height: 140px;
  border-radius: 5px;
  padding: 0 15px;
  width: 100%;
  line-height: 40px;
}
/*start:*/
.custom-inputdesign [type="radio"]:checked,
.custom-inputdesign [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.custom-inputdesign [type="radio"]:checked + label,
.custom-inputdesign [type="radio"]:not(:checked) + label
{
  position: relative;
  padding-left: 45px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #000;
  font-weight: bold;
  font-size: 17px;
}
.custom-inputdesign [type="radio"]:checked + label:before,
.custom-inputdesign [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  border: 1px solid #777;
  border-radius: 100%;
  background: #fff;
}
.custom-inputdesign [type="radio"]:checked + label:after,
.custom-inputdesign [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 17px;
  height: 17px;
  background: #1dbf73;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.custom-inputdesign [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.custom-inputdesign [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
/*enb:*/

.custom-inputdesign-group .custom-inputdesign + .custom-inputdesign {
  margin-left: 70px;
}
.custom-inputdesign-group {
  padding: 0 0 20px 0;
}

/*start:header autherinfo*/
.auther-infodetatil {
  position: absolute;
  right: 0;
  top: 25px;
  z-index: 1;
  font-size:0;
}
.auther-infodetatil button {
  border: none;
  background-color: #eee;
  padding: 0;
  margin: 0;
  width: 45px;
  height: 45px;
  border-radius: 45%;
  cursor: pointer;
}
.auther-infodetatil button:focus{
  border: none;
  box-shadow: none;
  outline: none;
}
.auther-infodetatil  img {
  object-fit: cover;
  width: 64px;
  height: 64px;
  border-radius: 64px;
  display: block;
}
.auther-infodetatil span.anticon.anticon-down {
  display: none;
}
.auther-infodetatil .dropdown-menu {
  background-color: #eee;
  display: flex;
  flex-direction: column;
  padding: 25px 20px;
  min-width: 170px;
  left: auto !important;
  right: 0 !important;
  top: 12px !important;
  border-radius: 3px;
}
.auther-infodetatil .dropdown-menu:before {
  content: '';
  width: 20px;
  height: 20px;
  background-color: #eee;
  position: absolute;
  right: 13px;
  top: -5px;
  transform: rotate(49deg);
}
.auther-infodetatil .dropdown-menu a {
  color: #373a3c;
  font-size: 17px;
  font-weight: 500;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.auther-infodetatil .dropdown-menu a:hover,.auther-infodetatil .dropdown-menu a:focus {
  color: #0072bc;
}
.auther-infodetatil .dropdown-menu a + a {
  margin-top: 18px;
}
/*end:header autherinfo*/
.trading-dashboard-page:after {
  content: '';
  width: 100%;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  //background-image: url(../../../../assets/images/shutterstock-1414654763.jpg);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  z-index: -1;
}

/*start:alert message*/
.alert-container{
  max-width: 450px;
  margin: 10px auto 10px auto;
}
.alertMessage{
  position: relative;
  padding: 0 15px 0 15px;
  border: 1px solid transparent;
  border-radius: .25rem;
}
.alertMessage .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1px 10px;
  color: inherit;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.alertMessage .close:focus {
  outline: none;
  box-shadow: none;
  border: none;
}
.alertMessage .close span {
  font-size: 25px;
  opacity: 0.8;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-success{
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
/*end:alert message*/


/*start:aboutus page content*/
.about-pagecontent {
  padding-top: 25px;
}
.about-pagecontent:after {
  content: '';
  width: 100%;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  z-index: -1;
}
.about-pagecontentbody {
  background-color: #fff;
  margin: 0 auto;
  max-width: 1030px;
  position: relative;
  padding: 20px 20px 10px 10px;
}
.about-textlist ul{
  padding: 20px 0 0 6px;
  margin: 0;
}
.about-textlist ul li {
  padding: 0 0 0 30px;
  margin: 0;
  list-style: none;
  position: relative;
  font-size: 18px;
}
.about-textlist ul li p{
  padding: 0;
  margin: 0;
}
.about-textlist ul li + li {
  margin-top: 15px;
}
.about-textlist ul li:before {
  content: '';
  width: 21px;
  height: 21px;
  position: absolute;
  left: 0;
  top: 5px;
  background-image: url(../../../../assets/images/signs.png);
  background-repeat: no-repeat;
}
.read-more-about {
  color: #2c8ac7;
  text-decoration: underline;
  font-weight: 500;
}

.aboutcontent-box {
  display: flex;
  padding-top: 60px;
}
.videobox {
  width: 50%;
  height: 265px;
}
.videobox iframe{
  width: 100%;
  height: 100%;
}
.brainexangebox {
  width: 50%;
  margin-left: 40px;
}
.about-pagecontentbody:after {
  content: 'c';
  position: absolute;
  right: 0;
  bottom: -60px;
  color: #4dc492;
  font-size: 21px;
  border: 1px solid #4dc492;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  text-align: center;
  display: block;
  line-height: 12px;
  display: none !important;
}
/*end:aboutus page content*/

/*start*/
.ant-radio-inner {
  border-color: #868585;
}
.ant-radio-inner:after {
  background-color: #1dbf73;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #1dbf73;
}
.ant-checkbox-checked:after{
  border-color: #1dbf73;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1dbf73;
  border-color: #1dbf73;
}
.ant-checkbox-inner{
  border-color: #000000;
}
/*end*/



/*start:common css*/
a {
  color: #1dbf74;
}
a:active, a:focus, a:hover{
  color: #0773b1;
}
input[type=email], input[type=password], input[type=tel],input[type=text] {
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: 700;
  background-color: #fff;
  color: #777;
  line-height: 20px;
  text-align: left;
  margin: 0;
  padding: 0 20px;
  outline: none;
  border: 1px solid #616161;
  border-radius: 7px;
}
button[type=submit]{
  height: auto;
  font-size: 16px;
  font-weight: 700;
  background-color: #1dbf74;
  color: #fff;
  line-height: 20px;
  text-align: center;
  margin: 0;
  padding: 13px 0;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all .5s ease 0s;
  text-transform: none;
  border-radius: 3px;
  margin-top: 16px;
}
button[type=submit]:hover,button[type=submit]:focus{
  color: #000;
  background-color: #1dbf74;
}
.ant-form button[type=submit]{
  width: 100%;
}
.ant-form  .ant-form-item-explain {
  padding-bottom: 7px;
}
/*end:common css*/
.header-signin-btn a{
  color: #000;
  font-size: 20px;
  font-weight: bold;
  border: 1px solid #000000;
  border-radius: 30px;
  padding: 6px 30px;
  transition: all 0.5s ease 0s;
}
.header-signin-btn a.active,
.header-signin-btn a:hover{
  border-color: #1dbf74;
}

.header-right {
  display: flex;
  margin-left: auto;
}
.header-inbox {
  margin: 0 70px;
}

.header-user-profile .ant-dropdown-trigger {
  cursor: pointer;
}
.header-right span img{
  cursor: pointer;
}

/*start: message-drop-down*/
.message-drop-down {
  width: 285px;
}
.message-item {
  display: flex;
  border-bottom: 1px solid rgba(0,0,0,0.09);
  padding: 0 0 8px 0;
  margin: 0 15px 8px 0;
  position: relative;
}
.message-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0,0,0,0.09);
  margin: 0 15px 8px 0;
  padding: 0 0 8px 0;
}
.message-header h3{
  margin: 0;
  padding: 0;
  font-size: 13px;
}
.message-header p{
  margin: 0;
  padding: 0;
  font-size: 13px;
}
.message-item-image {
  margin-right: 10px;
}
.message-item  span.user-name {
  font-weight: bold;
  color: #000;
}
.message-item .time {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 10px;
  font-weight: bold;
}
.message-item .title {
  color: #000;
}
.message-item-text {
  line-height: 18px;
}
.message-footer p {
  text-align: center;
  font-weight: bold;
  margin: 0;
}
/*end: message-drop-down*/



/*start: notification-drop-down*/
.notification-drop-down {
  width: 285px;
}
.notification-item {
  display: flex;
  border-bottom: 1px solid rgba(0,0,0,0.09);
  padding: 0 0 8px 0;
  margin: 0 15px 8px 0;
  position: relative;
}
.notification-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0,0,0,0.09);
  margin: 0 15px 8px 0;
  padding: 0 0 8px 0;
}
.notification-header h3{
  margin: 0;
  padding: 0;
  font-size: 13px;
}
.notification-header p{
  margin: 0;
  padding: 0;
  font-size: 13px;
}
.notification-item-image {
  margin-right: 10px;
}
.notification-item-icon {
  margin-right: 10px;
}
.notification-item  span.user-name {
  font-weight: bold;
  color: #000;
}
.notification-item .time {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 10px;
  font-weight: bold;
}
.notification-item .title {
  color: #000;
}
.notification-item-text {
  line-height: 18px;
}
.notification-footer p {
  text-align: center;
  font-weight: bold;
  margin: 0;
}
/*end: notification-drop-down*/
.listing-page-filter .select-box.search-box input.ant-input {
  height: 30px;
}
.modal-rating h3 {
  margin: 0;
  padding: 0;
}
.modal-review {
  padding: 15px 0 0 0;
}
.card-item .card-item-body .price .offer-price {
  padding-top: 6px;
}
.card-item-footer button {
  color: #fff;
  border: none;
  border-radius: 50px;
  padding: 12px 60px;
  font-size: 17px;
  font-weight: bold;
  transition: all .3s ease;
  background-color: #1dbf74;
  display: inline-block;
  height: auto;
}
.card-item-footer button:hover {
  background-color: #11a560;
}
.card-item-body .rating .rating-count {
  font-weight: 500;
  font-size: 16px;
  padding-right: 5px;
}
.card-item-body .rating svg {
  font-size: 14px;
}
.card-item-body .rating .ant-rate-star.ant-rate-star-zero svg{
  color: #d4d4d4;
}

.card-item .follow span {
  padding: 3px 0;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.listing-page-view-list .card-item-footer {
  text-align: left;
}

.card-item-body .image-title {
  color: #000;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
}
.card-item-middlepart .card-item-body .follow {
  margin-bottom: 25px;
}
.listing-page-container .listing-page-view-list .card-item {
  padding: 0;
  background-color: transparent;
  margin-right: 0;
}
.listing-page-container .listing-page-view-list .card-item .ant-row {
  background-color: #fff;
}

.listing-page-container .listing-page-view-list .card-item .ant-row + .ant-row {
  margin-top: 20px;
}
.card-item-leftpart {
  padding: 15px 0 0 15px;
}
.card-item-middlepart {
  padding: 25px;
}
.card-item-rightpart {
  padding-top: 55px;
  padding-bottom: 15px;
}

.left-sidebar-scrollbar div + div + div {
  width: 22px !important;
  background-color: #e9e9e9 !important;
  border-radius: 5px !important;
}
.left-sidebar-scrollbar div + div + div > div {
  background-color: #b5b5b5 !important;
  width: 12px !important;
  margin: 0 0 0 5px;
  border-radius: 8px !important;
}
.filter-bottom .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.filter-bottom .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: transparent;
  border-color: transparent;
  outline: none;
  box-shadow: none;
}
.filter-bottom .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
  background-color: transparent !important;
}


.textile-listing-page-container .listing-page-left-sidebar .left-sidebar-widget:nth-child(2) .left-sidebar-scrollbar div:nth-child(3){
  display: none;
}
.textile-listing-page-container .sidebar-left-content .listing-page-left-sidebar div:first-child .left-sidebar-scrollbar{
  max-height: 300px!important;
}
.textile-listing-page-container .sidebar-left-content .listing-page-left-sidebar div:first-child .left-sidebar-scrollbar div{
  max-height: 300px!important;
}
.textile-listing-page-container .left-sidebar-widget .ant-checkbox-group{
  height: 83px;
}
.textile-listing-page-container .price {
  flex-direction: column;
}
.listing-page-container{
  padding: 20px 80px 50px 150px;
}
.reset-button-box{
  border: 1px solid #d1d1d1;
  background-color: #fff;
  border-radius: 5px;
}
.reset-button-box span{
  color: #000;
}






.profile-listing-page-container .rating-count {
  margin-right: 5px;
}

.ant-message{
  font-size: 28px;
  padding: 50px;
}
.anticon-check-circle svg{
  width: 2em;
  height: 2em;
}

.form-page-drawer .ant-drawer-content-wrapper .ant-drawer-footer .ant-btn-primary{
  margin-left: 20px;
  border: none;
  border-radius: 3px;
  background-color: #1dbf73;
}

.fiber-listing-page-container .card-item-header .drop-down-action {
  position: absolute;
  right: 0;
}
.listing-page-view-list .card-item-body .image img, .listing-page-view-grid .card-item-body .image img{
  max-width: 100%;
}
.listing-page-view-grid .card-item-body .image img{
  height: 200px;
  width: 100%;
  object-fit: cover;
  margin-top: 15px;
}
.listing-page-view-grid .card-item-body .cotton-image-wrap .image img{
  max-width: 100%;
  height: unset;
  width: 55px;
}
.listing-page-view-grid .details{
  padding-top: 15px;
}

.listing-page-filter .filter-top .ant-breadcrumb {
  margin: 7px 0 0 0;
  padding-bottom: 35px;
}
.card-item-header {
  display: flex;
  position: relative;
  padding-bottom: 20px;
}
.card-item-header  .profile-name{
  max-width: 200px;
}
.listing-page-container .listing-page-view-grid .drop-down-action {
  position: absolute;
  right: 0;
}
.listing-page-container .listing-page-view-list .card-item-leftpart{
  padding: 15px;
}
.main-menu{
  position: fixed;
  z-index: 5;
  background: #ececec;
  width: 100%;
}
.full-pagecontent, .content-wrapper{
  padding-top: 145px;
}

.listing-page-filter .filter-top .ant-breadcrumb span,
.listing-page-filter .filter-top .ant-breadcrumb a,
.ant-breadcrumb .ant-breadcrumb-link{
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 53px;
}
.header-pagecontentbody .img-tiktiki{
  position: absolute;
  left: 100px;
  top: -14px;
}
.header-pagecontentbody .img-tiktiki img{
  height: 50px!important;
  max-width: 100%;
}
.footer-pagecontentbody .img-tiktiki img{
  max-width: 100%;
}

//user admin dashboard start
.admin-dashboard-wrapper .main-layout{
  box-sizing: border-box;
  padding-top: 200px;
  display: flex;
  justify-content: flex-end;
}
.chart-wrapper{
  background: #fff;
  padding: 20px;
}
.chart-wrapper .chartjs-render-monitor{
  max-width: 100%;
}

//.admin-dashboard-wrapper .main-layout .dashboard-section-right{
//  padding-left: 50px;
//}
//
.admin-dashboard-wrapper .main-layout .content-wrapper{
  padding: 0 20px;
  width: calc(100% - 200px);
}
.admin-dashboard-wrapper .ant-layout-sider{
  background: #fff;
}
.admin-dashboard-wrapper .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #1dbf74;
}
.admin-dashboard-wrapper .main-layout .app-left-nav .ant-menu-item a{
  display: flex;
  justify-content: space-between;
  color: #131c25;
  font-size: 16px;
  font-weight: 600;
}
.admin-dashboard-wrapper .ant-menu-item-selected, .ant-menu-item-selected a, .admin-dashboard-wrapper .ant-menu-item-selected a:hover{
  color: #fff;
}
.admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section{

}
.admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-overview .card-header h3,
.dashboard-card-item-map .card-header h3,
.dashboard-card-item-user-state .card-header h3{
  font-weight: 700;
  color: #a5acb3;
  font-size: 20px;
}
.admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-card-item-map .country-row{
    display: flex;
  justify-content: space-between;
  color: #131c25;
  font-size: 16px;
  font-weight: 600;
}

.admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-overview .card-content{
  font-size: 24px;
  font-weight: 700;
  color: #131c25;
}
.admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-overview .card-content .card-icon{
  float: right;
  clear: both;
}
.admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-overview .dashboard-cart-item {
  background: #fff;
  // width: 25%;
  margin: 0 15px;
  padding: 20px;
  margin-bottom: 25px;
}
.admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-overview .dashboard-cart-item-active-user .card-icon{
  position: relative;
}
.admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-overview
.dashboard-cart-item-active-user .card-icon::before{
  position: absolute;
  content: '';
  width: 20px;
  height: 20px;
  background: #1dbf74;
  border-radius: 100%;
  right: 0;
  top: -10px;
  border: 3px solid #a7f7d2;
}
.admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-middle{
  margin: 0px 15px 0 15px;
  display: flex;
}
.admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-middle .card-footer{
  display: flex;
  justify-content: space-between;
}
.admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-middle .card-footer .footer-item{
  padding-left: 20px;
  position: relative;
}
.admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-middle .card-footer .footer-item span{
  display: block;
}
.admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-middle .card-footer .footer-item .active-user-label{
  font-size: 16px;
  font-weight: 600;
  color: #131c25;
}
.admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-middle .card-footer .footer-item:first-child:before{
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: #1dbf74;
  left: 0;
  top: 8px;
  border-radius: 100%;
}
.admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-middle .card-footer .footer-item:last-child:before{
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: #aeb5bd;
  left: 0;
  top: 9px;
  border-radius: 100%;
}

.admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-middle .dashboard-section-left{
  width: 75%;
}
.admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-middle .dashboard-section-right{
  width: 25%;
  padding-left: 25px;
}
.admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-middle .dashboard-section-right .dashboard-card-item-map,
.admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-middle .dashboard-section-right .dashboard-card-item-user-state{
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
}
.admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-middle .dashboard-cart-item-communication-graph{
  margin-top: 20px;
  margin-bottom: 20px;
}
.admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-footer{
  margin: 0 15px;
}
.admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-footer .dashboard-chart-item{
  width: 100%;
}
.admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section .chart-content img{
  max-width: 100%;
  width: 100%;
}
.admin-dashboard-wrapper .main-layout .ant-layout-sider-children .ant-menu-inline-collapsed{
  width: unset;
}
.admin-dashboard-wrapper .main-layout .ant-menu.navigation-menu.ant-menu-light.ant-menu-root.ant-menu-vertical{
  position: fixed;
  z-index: 2;
  height: 100%;
  left: 0;
  width: 200px;
}
.admin-dashboard-wrapper .main-layout .content-wrapper .card-wrapper-user-state .active-user-online-card-content{
  padding: 20px;
}
.admin-dashboard-wrapper .main-layout .content-wrapper .card-wrapper-user-state .active-user-online{
  border: 10px solid #1dbf74;
  border-radius: 100%;
  width: 150px;
  height: 150px;
  display: block;
  text-align: center;
  vertical-align: middle;
  line-height: 130px;
  font-size: 36px;
  font-weight: 700;
  color: #131c25;
  margin: 0 auto;
}

.admin-dashboard-wrapper .user-content-body .listing-page-filter .breadcrumb,
.admin-dashboard-wrapper .user-content-body .listing-page-filter .btn-group-right{
  display: inline-block;
}
.admin-dashboard-wrapper .user-content-body .listing-page-filter .btn-group-right{
  float: right;
  clear: both;
}
.admin-dashboard-wrapper .user-content-body .listing-page-filter .filter-bottom,
.admin-dashboard-wrapper .user-content-body .listing-page-filter .filter-bottom .filter-bottom-left,
.admin-dashboard-wrapper .user-content-body .listing-page-filter .filter-bottom .filter-bottom-left .ant-form-item-control-input-content,
.admin-dashboard-wrapper .user-content-body .listing-page-filter .filter-bottom .filter-bottom-right{
  display: flex;
}
.admin-dashboard-wrapper .user-content-body .listing-page-filter .filter-bottom{
  justify-content: space-between;
}
.admin-dashboard-wrapper .user-content-body .listing-page-filter .filter-bottom .filter-bottom-left{
  align-items: end;
}
.admin-dashboard-wrapper .user-content-body .listing-page-filter .filter-bottom .filter-bottom-left .filter-box-left{
  margin-right: 20px;
}
.admin-dashboard-wrapper .user-content-body .listing-page-filter .filter-bottom .filter-bottom-right{
  flex-basis: 10%;
  justify-content: flex-end;
}
.admin-dashboard-wrapper .user-content-body .listing-page-filter .filter-bottom .filter-bottom-right .reload-button-box .ant-btn-link{
    background: #fff;
    border: 1px solid #d9d9d9;
    margin-right: 20px;
  color: #000;
}
.admin-dashboard-wrapper .user-content-body .listing-page-view .ant-switch-checked {
  background-color: #1dbf74;
}

.admin-dashboard-wrapper .user-content-body .listing-page-filter .filter-top .btn-group-right .ant-btn-primary {
  color: #fff;
  background: #1dbf74;
  border-color: #1dbf74;
  text-shadow: 0 -1px 0 rgba(0,0,0,.12);
  -webkit-box-shadow: 0 2px 0 rgba(0,0,0,.045);
  box-shadow: 0 2px 0 rgba(0,0,0,.045);
  transition: 0.5s;
}
.admin-dashboard-wrapper .user-content-body .listing-page-filter .filter-top .btn-group-right .ant-btn-primary:hover{
  background: #119659!important;
}
.admin-dashboard-wrapper .user-content-body .listing-page-filter .filter-bottom .filter-bottom-left .ant-input{
  height: auto;
}
.admin-dashboard-wrapper .user-content-body .listing-page-pagination{
  margin-top: 20px;
}
.admin-dashboard-wrapper .user-content-body .listing-page-pagination .ant-pagination{
  text-align: right;
}
//user admin dashboard end


//active-user-online-card-content start
// -- vars
$bg-color: #34495e;
$default-size: 1em;
$label-font-size: $default-size / 4;
$label-font-size-redo: $default-size * 4;

// -- mixins
@mixin size($width, $height) {
  height: $height;
  width: $width;
}

@mixin draw-progress($progress, $color) {
  .pie {
    .half-circle {
      border-color: $color;
    }

    .left-side {
      transform: rotate($progress * 3.6deg);
    }

    @if $progress <= 50 {
      .right-side {
        display: none;
      }
    } @else {
      clip: rect(auto, auto, auto, auto);

      .right-side {
        transform: rotate(180deg);
      }
    }
  }
}

@mixin draw-progress--solid($progress, $color, $bg-color) {
  background: linear-gradient(to right, $color 50%, $bg-color 50%);

  &:before {
    @if $progress <= 50 {
      background: $bg-color;
      transform: rotate((100 - (50 - $progress)) / 100 * 360deg * -1);
    } @else {
      background: $color;
      transform: rotate((100 - $progress) / 100 * 360deg);
    }
  }
}

// -- selectors
*,
*:before,
*:after {
  box-sizing: border-box;
}

.set-size {
  font-size: 10em;
}

.charts-container {
  &:after {
    clear: both;
    content: '';
    display: table;
  }
}

.pie-wrapper {
  @include size($default-size, $default-size);
  margin: 0 auto;
  text-align: center;
  position: relative;

  &:nth-child(3n + 1) {
    clear: both;
  }

  .pie {
    @include size(100%, 100%);
    clip: rect(0, $default-size, $default-size, $default-size / 2);
    left: 0;
    position: absolute;
    top: 0;

    .half-circle {
      @include size(100%, 100%);
      border: ($default-size / 10) solid #3498db;
      border-radius: 50%;
      clip: rect(0, $default-size / 2, $default-size, 0);
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  .label {
    background: $bg-color;
    border-radius: 50%;
    bottom: $label-font-size-redo / 10;
    color: #ecf0f1;
    cursor: default;
    display: block;
    font-size: $label-font-size;
    left: $label-font-size-redo / 10;
    line-height: $label-font-size-redo * .70;
    position: absolute;
    right: $label-font-size-redo / 10;
    text-align: center;
    top: $label-font-size-redo / 10;

    .smaller {
      color: #bdc3c7;
      font-size: .45em;
      padding-bottom: 20px;
      vertical-align: super;
    }
  }

  .shadow {
    @include size(100%, 100%);
    border: $default-size / 10 solid #bdc3c7;
    border-radius: 50%;
  }

  &.style-2 {
    .label {
      background: none;
      color: #7f8c8d;

      .smaller {
        color: #bdc3c7;
      }
    }
  }
  &.progress-45 {
    @include draw-progress(45, #1dbf74);
  }
}

.pie-wrapper--solid {
  border-radius: 50%;
  overflow: hidden;

  &:before {
    border-radius: 0 100% 100% 0 / 50%;
    content: '';
    display: block;
    height: 100%;
    margin-left: 50%;
    transform-origin: left;
  }

  .label {
    background: transparent;
  }

  &.progress-65 {
    @include draw-progress--solid(65, #e67e22, $bg-color);
  }

  &.progress-25 {
    @include draw-progress--solid(25, #9b59b6, $bg-color);
  }

  &.progress-88 {
    @include draw-progress--solid(88, #3498db, $bg-color);
  }
}

//active-user-online-card-content end

.listing-page-container .card-item{
  background: #fff;
  padding: 15px;
  // height: 100%;
}



.ant-badge-count{
  display: none!important;
}

.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-item, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-vertical .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title {
  overflow: unset;
}
.ant-menu-vertical-left.ant-menu-sub, .ant-menu-vertical-right.ant-menu-sub, .ant-menu-vertical.ant-menu-sub{
  height: 200px;
  max-width: auto;
}
.ant-btn-primary:focus, .ant-btn-primary:hover {
   color: #fff;
   background: #1dbf74;
   border-color: #1dbf74;
 }

.iframe-warning-content{
  text-align: center;
}
.iframe-warning-content p{
  font-size: 25px;
  color: #ff4d4f;
}
.iframe-warning-content p:first-child{
  margin-bottom: 5px;
}
.iframe-warning-content p a{
  background: #1dbf73;
  border-radius: 100px;
  color: #fff;
  padding: 5px 20px;
  font-weight: bold;
}

.pie-wrapper .label {
  left: 8px;
  position: absolute;
  top: 48px;
}
.pie-wrapper .label .smaller{
  position: absolute;
}
.pie-wrapper{
  top: -32px;
}
.language {
  margin-left: auto;
  width: 191px;
  max-width: 191px;
}
.header-user-profile{
  margin-left: auto;
}
#google_translate_element{width: 100%!important;}

.chain-item-block.logistic .chain-item-inner:after{
  width: 97px;
  left: 85%;
  transform: rotate(-35deg);
  top: 51%;
}
.language .goog-te-gadget .goog-te-combo{
  background: transparent;
}










@media screen and (width: 1920px){
  // .chain-item-block.logistic .chain-item-inner:after {
  //   width: 100px;
  //   left: 89%;
  //   transform: rotate(-31deg);
  // }
  .chain-item-block.logistic.buyPost > h4{
    width: 117.7px;
  }
}

@media screen and (width: 1903px){
  .chain-item-block.logistic.buyPost > h4{
    width: 111.7px;
  }
}
@media screen and (min-width: 1681px) and (max-width: 1950px) {
  .createaccountpage{
    padding: 6px 0 0 0;
  }
  .createaccount-contentbody {
    padding: 20px;
  }
  .ant-form-item {
    margin: 0 0 10px;
  }
  .createaccountpage:after {
    height: 120vh;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1730px) {
  .chain-item-block.logistic .chain-item-inner:after{
    width: 91px;
    left: 87%;
    transform: rotate(-35deg);
  }
  .big-circleblock-contentbody {
    padding: 0;
  }
  .chain-section {
    padding: 0px 0 0px 0;
  }
  .chain-item-thumb img {
    width: 50%;
  }
  .chain-section .chain-item-block {
    padding-right: 15px;
    padding-left: 15px;
  }
  .chain-item-inner:after {
    content: "";
    position: absolute;
    width: 69px;
    height: 10px;
    border-top: 2px dashed #004cff;
    left: 93%;
    transform: rotate(-36deg);
    top: 43%;
  }
  .chain-item-block.yarn .chain-item-inner:after {
    width: 48px;
  }
  .chain-item-block.design .chain-item-inner:after {
    width: 70px;
    left: 91%;
  }
  .chain-item-block.textiles .chain-item-inner:after {
    width: 48px;
  }
  .chain-item-block.consultant .chain-item-inner:after {
    width: 81px;
    left: 89%;
  }
  .chain-item-block.garment .chain-item-inner:after {
    width: 84px;
    left: 90%;
  }
.chain-item-block.knowledge .chain-item-inner:after {
  width: 78px;
  left: 90%;
  }
  .follow-us {
    padding: 0px 0px 0px 0px;
  }
  .admin-login-box {
    max-width: 440px;
    padding: 15px;
  }
  .admin-loginpage {
    padding: 0px 0 0px 0;
  }
  .admin-login-box {
    max-width: 430px;
    padding: 15px;
  }
  .admin-loginpage {
    padding: 0px 0 0px 0;
  }
  input[type=email], input[type=password], input[type=tel], input[type=text] {
    height: 30px !important;
    font-size: 16px;
    line-height: 20px;
    padding: 0 20px;
  }
  .adminlogin-logo {
    text-align: center;
    padding: 0 0 10px 0;
  }
  button[type=submit] {
    font-size: 15px;
    line-height: 15px;
    padding: 8px 0;
  }
  .ant-form-item {
    margin: 0 0 12px;
  }
  .createaccountpage{
    padding: 0px 0 0 0;
  }
  .createaccount-contentbody {
    padding: 20px;
  }
  .ant-form-item {
    margin: 0 0 10px;
  }
  .createaccount-contentbody {
    max-width: 420px;
  }
  .createaccountpage:after {
    height: 130vh;
  }
  // .chat-room .chat-room-conversation .scrollEle{
  //   max-height: 280px!important;
  // }
  .chat-room .chat-room-input input[type=text]{
    padding: 25px 20px;
  }
}
@media screen and (width: 1680px){
  .chain-item-block.logistic.buyPost > h4{
    width: 117.7px;
  }
  .chain-item-inner:after{
    width: 65px;
  }
  .chain-item-block.yarn .chain-item-inner:after {
    width: 50px;
    left: 92%;
    top: 47%;
    transform: rotate(-41deg);
  }
  .chain-item-block.textiles .chain-item-inner:after {
    width: 57px;
    left: 93%;
    top: 46%;
    transform: rotate(-40deg);
  }
  .chain-item-block.design .chain-item-inner:after {
    width: 68px;
    left: 92%;
  }
  .chain-item-block.consultant .chain-item-inner:after {
    width: 76px;
    left: 92%;
  }
  .chain-item-block.knowledge .chain-item-inner:after {
    width: 74px;
    left: 92%;
  }
  .chain-item-block.logistic .chain-item-inner:after {
    width: 48px;
    left: 95%;
    transform: rotate(-35deg);
  }


}

@media screen and (width: 1600px){
  .chain-item-block.logistic.buyPost > h4{
    width: 117.7px;
  }
  .chain-item-inner:after {
    width: 64px;
    left: 93%;
    transform: rotate(-36deg);
    top: 43%;
  }
  .chain-item-block.yarn .chain-item-inner:after {
    width: 43px;
    left: 95%;
  }
  .chain-item-block.textiles .chain-item-inner:after {
    width: 50px;
    left: 97%;
  }
  .chain-item-block.design .chain-item-inner:after {
    width: 68px;
    left: 92%;
  }
  .chain-item-block.consultant .chain-item-inner:after {
    width: 76px;
    left: 91%;
  }
  .chain-item-block.knowledge .chain-item-inner:after {
    width: 76px;
    left: 91%;
  }
  .chain-item-block.logistic .chain-item-inner:after {
    width: 48px;
    left: 94%;
    transform: rotate(-35deg);
  }


}
@media screen and (width: 1536px){
  .chain-item-block.logistic.buyPost > h4{
    width: 117.7px;
  }
  .chain-item-inner:after{
    width: 64px;
  }
  .chain-item-block.yarn .chain-item-inner:after {
    width: 41px;
    left: 97%;
  }
  .chain-item-block.textiles .chain-item-inner:after {
    width: 48px;
    left: 97%;
  }
  .chain-item-block.design .chain-item-inner:after {
    width: 68px;
    left: 92%;
  }
  .chain-item-block.consultant .chain-item-inner:after {
    width: 78px;
    left: 91%;
  }
  .chain-item-block.logistic .chain-item-inner:after {
    width: 48px;
    left: 95%;
  }
  .chain-item-block.knowledge .chain-item-inner:after{
    width: 74px;
    left: 92%;
  }










}
@media screen and (width: 1440px) {
  .chain-item-block.logistic.buyPost > h4{
    width: 117.7px;
  }
  .chain-item-inner:after{
    width: 61px;
  }
  .chain-item-block.yarn .chain-item-inner:after {
    width: 38px;
    left: 96%;
  }
  .chain-item-block.textiles .chain-item-inner:after {
    width: 45px;
    left: 98%;
  }
  .chain-item-block.design .chain-item-inner:after {
    width: 64px;
    left: 93%;
  }
  .chain-item-block.consultant .chain-item-inner:after {
    width: 77px;
    left: 91%;
  }
  .chain-item-block.garment .chain-item-inner:after {
    width: 79px;
    left: 91%;
  }
  .chain-item-block.knowledge .chain-item-inner:after {
    width: 72px;
    left: 92%;
  }
  .chain-item-block.logistic .chain-item-inner:after {
    width: 56px;
    left: 94%;
}
  .chain-item-block.logistic.buyPost .chain-item-inner:after {
    width: 45px;
    left: 94%;
}









}
@media screen and (min-width: 1280px) and (max-width: 1451px) {
  .chain-section .chain-item-block{
    padding-right: 14px;
    padding-left: 14px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .chain-item-block h4{
    padding: 0;
  }
  .chain-item-block h4:before{
    display: none;
    visibility: hidden;
    opacity: 0;
  }
  .chain-section .chain-item-block {
    padding-right: 15px;
    padding-left: 15px;
  }
  .chain-item-thumb{
    width: 65px;
    height: 65px;
  }
  .chain-item-inner{
    width: 85px;
    height: 85px;
  }
  .chain-item-inner:after{
    width: 60px;
  }
  .chain-item-block.design .chain-item-inner:after{
    width: 62px;
  }
  .chain-item-block.consultant .chain-item-inner:after {
    width: 57px;
    left: 92%;
  }
  .chain-item-block.garment .chain-item-inner:after {
    width: 61px;
    left: 92%;
  }
  .chain-item-block.knowledge .chain-item-inner:after {
    width: 59px;
    left: 92%;
  }









} 
@media screen and (min-width: 1024px) and (max-width: 1279px)   {
  .language .goog-te-gadget .goog-te-combo{
    font-size: 14px;
  }
  .goog-te-gadget{
    height: auto!important;
  }
  .chain-item-thumb img {
    width: 40%;
  }
  .chain-item-thumb {
    width: 50px;
    height: 50px;
    padding: 0px;
  }
  .chain-item-inner::before {
    width: 80%;
    height: 80%;
  }
  .chain-section {
    padding: 20px 0 20px 0;
  }
  .chain-section .chain-item-block {
    padding-right: 8px;
    padding-left: 8px;
  }
  .big-circleblock-contentbody {
    padding: 70px 0 0 0;
  }
  .chain-item-block h4 {
    font-size: 16px;
  }
  .chain-item-inner {
    width: 85px;
    height: 85px;
  }
  .chain-item-inner:after {
    width: 74px;
    height: 55px;
    left: 75%;
    top: 36%;
  }
  .chain-item-block.yarn .chain-item-inner:after {
    width: 68px;
  }
  .chain-item-block.textiles .chain-item-inner:after {
    width: 69px;
  }
  .chain-item-block.design .chain-item-inner:after {
    width: 74px;
  }
  .chain-item-block.consultant .chain-item-inner:after {
    width: 88px;
  }
  .chain-item-block.knowledge .chain-item-inner:after {
    width: 75px;
  }
  .chain-item-block.garment .chain-item-inner:after {
    width: 80px;
  }
  .header-right {
    right: 150px;
  }
  .header-inbox {
    margin: 0 30px;
  }
  .admin-login-box {
    max-width: 430px;
    padding: 15px;
  }
  .admin-loginpage {
    padding: 0px 0 0px 0;
  }
  
  input[type=email], input[type=password], input[type=tel], input[type=text] {
    height: 30px !important;
    font-size: 16px;
    line-height: 20px;
    padding: 0 20px;
  }
  .sendMsgBtn input{
    height: 50px !important;
  }
  .adminlogin-logo {
    text-align: center;
    padding: 0 0 10px 0;
  }
  button[type=submit] {
    font-size: 15px;
    line-height: 15px;
    padding: 8px 0;
  }
  .ant-form-item {
    margin: 0 0 12px;
  }
  .adminlogin-logo img {
    height: 70px;
    width: 60px;
  }
  .createaccountpage{
    padding: 0px 0 0 0;
  }
  .header-pagecontent .main-menulist li a{
    font-size: 14px;
  }
  .header-pagecontent .main-menulist{
    left: 262px;
  }
  .header-pagecontent .header-right{
     right: 262px;
     top: 46px;
   }
  .header-pagecontent .language{
    right: 25px;
    top: 46px;
  }
  .filter-top .fiber-type .green-btn{
    padding: 5px 10px!important;
    font-size: 14px;
  }
  .listing-page-filter .filter-top .fiber-type{
    padding-left: 15px;
  }
  .listing-page-filter .filter-top .fiber-type .green-btn{
    margin-right: 4px;
  }
  .listing-page-filter .filter-top .btn-group .green-btn + .green-btn{
    margin-left:10px;
  }
  .listing-page-container .listing-page-filter .filter-top .btn-group a {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDowREY1QTZEQkMzOTIxMUVBOTY0MURCRjUxNjU5RDI3OSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDowREY1QTZEQ0MzOTIxMUVBOTY0MURCRjUxNjU5RDI3OSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjBERjVBNkQ5QzM5MjExRUE5NjQxREJGNTE2NTlEMjc5IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjBERjVBNkRBQzM5MjExRUE5NjQxREJGNTE2NTlEMjc5Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+uGi1oQAAAd5JREFUeNqslYttwjAQhk3FAO4G6QbpBnQCwgbuBM0GZQOyAe4E0AlgA9IJmk5AmMA909/lZM4mCE76lAeOf98T5ZxTd6Amdu7P9sSSKMLvD+o208SGqIhXYkQ8Ez9ES5THVQNPWhI6eqdxeovnCfFOvOG5IrprPPEnrQUPvgiDdwVxIF6IFbEmev/t+IYQcQHL7htsriBUpjw5xfPcLBMw8NKw34voQH0uBx2uCpXic2CIFu/CvRbyZHD/7fe4lOw2erdGQgt3sh025AIKBdH4+2v7YYsqWrOqakAs8O8lT7zPwZQ9fxBdlA+f0IVQVVP0iQV+r0koAC7SoZn4hpLFVeVFZqikMwFvo2PMZDM4oWYfHCKBsM6vWRJPsUCq430cV4hphRy0LAch5t42wIUkS4wzjVYiFAv0TezBI+ulWSa8KjeLDE4oeaCFE1vmGf9G5QRabMg3sayMB5c+r38rdLK/LliOlCBSssmQFKnxoTQqNBLqUARaCFc8gkSRFgsmWJyaRWGsNCx8g4QUi/scXqUEbDTLtsKsS4r0GHg1Yh+mbnIWJUSyObHwIoRjD+GcQByuiyIF4lkJnb9MCFxFmF0lBtweV41/vE9inu3mARYPyAqCPcQ6dQf7FWAAi+f73CvsKaUAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-position: 9px center;
    padding: 7px 9px 5px 37px;
    font-size: 14px;
    font-weight: bold;
  }
  .listing-page-filter .filter-top .fiber-type{
    padding-bottom: 0;
  }
  .listing-page-container .listing-page-filter .filter-bottom {
    padding: 15px 0;
  }
  .listing-page-container .listing-page-filter .filter-bottom{
    flex-wrap: wrap;
  }
  .listing-page-container.fiber-cotton-listing-page-container .listing-page-filter .filter-bottom .ant-radio-group.ant-radio-group-solid, .supply-chain-listing-page-container .listing-page-filter .filter-bottom .ant-radio-group.ant-radio-group-solid, .fiber-listing-page-container .listing-page-filter .filter-bottom .ant-radio-group.ant-radio-group-solid{
    flex-basis: initial!important;
  }
  .header-quick-menu .ant-menu-horizontal {
    display: flex;
    justify-content: space-between;
  }
  // .chat-room .chat-room-conversation .scrollEle{
  //   max-height: 380px!important;
  // }
  // .chat-room .chat-room-input input[type=text]{
  //   padding: 25px 20px;
  //   width: calc(38vh);
  // }

}
@media screen and (max-width: 1366px) {
  .chain-item-block.logistic .chain-item-inner:after{
    width: 88px;
  }
}
@media screen and (width: 1366px) {
  .chain-item-block.logistic.buyPost > h4{
    width: 117.7px;
  }
  .chain-item-block.logistic .chain-item-inner:after{
    width: 88px;
  }
  .chain-item-main {
    max-width: 88px;
  }
  .chain-section .chain-item-block{
    padding-right: 11px;
    padding-left: 11px;
  }
  .chain-item-inner{
    width: 92px;
    height: 92px;
  }
  .chain-item-thumb{
    width: 72px;
    height: 72px;
  }
  .chain-item-inner:after{
    width: 61px;
  }
  .chain-item-block.textiles .chain-item-inner:after{
    width: 51px;
    left: 96%;
  }
  .chain-item-block.logistic.buyPost .chain-item-inner:after{
    width: 53px;
    left: 90%;
  }
  .chain-item-block.yarn .chain-item-inner:after{
    width: 41px;
    left: 96%;
  }
  .chain-item-block.yarn .chain-item-inner:after{width: 38px;}
  .chain-item-block.design .chain-item-inner:after{width: 67px;}
  .chain-item-block.garment .chain-item-inner:after{width: 80px;}
  .chain-item-block.knowledge .chain-item-inner:after{width: 75px;}
  .chain-item-block.logistic .chain-item-inner:after{width: 82px;}



}

@media screen and (max-width: 1280px){
  .chain-item-block.logistic.buyPost .chain-item-inner:after{
    width: 47px;
    left: 92%;
  }
  .chain-item-block.logistic.buyPost > h4{
    width: 85px;
  }
}
@media screen and (max-width: 1199px) {
  .admin-dashboard-wrapper .main-layout{
    display: block;
  }
  .admin-dashboard-wrapper .main-layout .ant-menu.navigation-menu.ant-menu-light.ant-menu-root.ant-menu-vertical{
    position: static;
    margin-bottom: 30px;
    margin-left: 35px;
  }
  .admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-middle .dashboard-section-right,
  .admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-middle .dashboard-section-left,
  .admin-dashboard-wrapper .main-layout .content-wrapper{
    width: 100%;
  }
  .chart-wrapper .chartjs-render-monitor {
    width: 100%!important;
  }
  
}

@media screen and (min-width: 1025px) and (max-width: 1280px){
  .chain-item-block.logistic .chain-item-inner:after{
    width: 76px;
  }
  .chain-item-block.yarn .chain-item-inner:after {
    width: 41px;
    left: 97%;
  }
  .chain-item-block.textiles .chain-item-inner:after {
    width: 42px;
    left: 97%;
  }
  .chain-item-block.logistic .chain-item-inner:after {
    width: 65px;
    left: 90%;
  }
}


@media screen and (width: 1080px) {
  .chain-section .chain-item-block {
    padding-right: 4px;
    padding-left: 4px;
  }
  .chain-item-inner:after {
    width: 60px;
    height: auto;
    left: 68%;
    top: 36%;
  }
  .chain-item-block.yarn .chain-item-inner:after {
    width: 43px;
    left: 69%;
    top: 40%;
  }
  .chain-item-block.textiles .chain-item-inner:after {
    left: 70%;
  }
  .chain-item-block.logistic.buyPost .chain-item-inner:after {
    width: 47px;
    left: 64%;
  }
  .chain-item-block.design .chain-item-inner:after {
    width: 64px;
    left: 66%;
  }
  .chain-item-block.consultant .chain-item-inner:after {
    width: 61px;
    left: 70%;
  }
  .chain-item-block.garment .chain-item-inner:after {
    width: 67px;
    left: 69%;
  }
  .chain-item-block.knowledge .chain-item-inner:after {
    width: 61px;
    left: 69%;
  }



}

@media screen and (max-width: 1024px){
  .chain-item-block.logistic.buyPost > h4{
    width: 85px;
  }
  .buyerSLider{
    width: 100%;
  }
  .admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-middle{
    display: block;
  }
  .admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-middle .dashboard-section-right{
    padding-left: 0;
  }
  .chain-item-block h4{
    padding: 0;
  }
  .chain-item-block.logistic .chain-item-inner:after{
    width: 60px;
  }
  .chain-item-block h4:before{
    display: none;
    visibility: hidden;
    opacity: 0;
  }
  .chain-item-inner:after{
    width: 52px;
    left: 85%;
  }
  .chain-item-block.textiles .chain-item-inner:after,
  .chain-item-block.yarn .chain-item-inner:after {
    width: 51px;
    left: 87%;
    top: 34%;
  }
  .chain-item-block.logistic.buyPost .chain-item-inner:after {
    width: 47px;
    left: 87%;
    top: 37%;
  }
  .chain-item-block.design .chain-item-inner:after {
    width: 55px;
  }
  .chain-item-block.consultant .chain-item-inner:after {
    width: 51px;
    left: 85%;
  }
  .chain-item-block.garment .chain-item-inner:after {
    width: 50px;
    left: 87%;
  }
  .chain-item-block.knowledge .chain-item-inner:after {
    width: 46px;
    left: 88%;
  }
  .chain-item-block.logistic .chain-item-inner:after {
    width: 45px;
    left: 88%;
  }



}

@media screen and (max-width: 1023px){
  .goog-te-gadget{
    height: auto;
  }
  .language .goog-te-gadget .goog-te-combo,
  .main-menulist li a{
    font-size: 14px;
  }
  .header-inbox {
    margin: 0 20px;
  }
  .chain-item-block h4{
    padding: 0 0 0 30px;
  }
  .chain-item-block h4:before{
    display: none;
    visibility: hidden;
    opacity: 0;
  }
  // .chain-item-inner:after{
  //   display: none;
  //   opacity: 0;
  //   visibility: hidden;
  // }
  .chain-section .chain-item-block{
    margin-bottom: 25px;
  }
  .chain-section{
    display: block;
  }
  .chain-section-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    gap: 20px 20px;

  }
  .chain-section .chain-item-block{
    padding-left: 20px;
    padding-right: 20px;
  }
  .card-item-rightpart.es_res button {
    padding: 12px 25px;
  }
  
}
@media screen and (max-width: 991px){
  .header-inbox {
    margin: 0 50px;
  }
  .header-signin-btn a,
	.main-menulist li a{
		font-size: 18px;
	}

	.main-menulist ul{
		margin-top: 50px;
	}
  .header-signin-btn li,
	.main-menulist li {
		display: block;
		padding: 0;
    margin-bottom: 20px;
	}
  .header-signin-btn a,
  .header-signin-btn li a,
	.main-menulist li a {
		display: block;
		border-radius: 0;
		border-left: 0px;
		border-right: 0px;
	}
  .main-menulist li a.active{
    border-radius: 0px;
    border-left: 0px;
		border-right: 0px;
  }
}
@media screen and (max-width: 800px){
  .chain-item-block.logistic.buyPost > h4{
    width: 145px;
  }
  .chain-item-block h4{
    padding: 0;
  }
  .chain-item-block.knowledge .chain-item-inner:after {
    width: 143px!important;
    left: 82%!important;
    top: 51%;
    transform: rotate(-27deg);
  }
  .chain-item-block.garment .chain-item-inner:after,
  .chain-item-block.textiles .chain-item-inner:after{
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  .chain-item-inner:after {
    content: "";
    position: absolute;
    width: 164px!important;
    height: 16px;
    border-top: 2px dashed #004cff;
    left: 80%!important;
    transform: rotate(-27deg);
    top: 43%;
  }
  .chain-item-block.yarn .chain-item-inner:after {
    width: 160px!important;
    left: 82%!important;
    top: 44%;
    transform: rotate(-29deg);
  }
  .chain-item-block.logistic .chain-item-inner:after,
  .chain-item-block.garment .chain-item-inner:after,
  .chain-item-block.consultant .chain-item-inner:after,
  .chain-item-block.design .chain-item-inner:after {
    width: 154px!important;
    left: 83%!important;
    transform: rotate(-27deg);
  }
  .chain-item-block.logistic.buyPost .chain-item-inner:after {
    width: 148px!important;
    left: 84%!important;
    top: 46%;
    transform: rotate(-28deg);
  }
















}
@media screen and (max-width: 768px){
  .chain-item-block.logistic.buyPost > h4{
    width: 137px;
  }
  .chart-wrapper.chart-wrapper-post-line-chart .chart-content{
    width: 1440px!important;
    height: 304px!important;
  }
  .chart-wrapper.chart-wrapper-post-line-chart{
    overflow-x: auto;
  }
  .chart-wrapper.chart-wrapper-communication-bar-chart,
  .chart-wrapper.chart-wrapper-user-area-chart{
    overflow-x: auto;
  }
  .chart-wrapper.chart-wrapper-user-area-chart .chart-content canvas,
  .chart-wrapper.chart-wrapper-user-area-chart .chart-content{
    width: 854px !important;
    height: 200px !important;
  }
  .chart-wrapper.chart-wrapper-communication-bar-chart .chart-content canvas,
  .chart-wrapper.chart-wrapper-communication-bar-chart .chart-content{
    height: 254px !important;
    width: 1080px !important;
  }



  .chain-item-inner:after {
    width: 142px !important;
    left: 85% !important;
    transform: rotate(-27deg);
    top: 43%;
  }
  .chain-item-block.yarn .chain-item-inner:after{
    width: 152px!important;
  }
  .chain-item-block.logistic .chain-item-inner:after, .chain-item-block.garment .chain-item-inner:after, .chain-item-block.consultant .chain-item-inner:after, .chain-item-block.design .chain-item-inner:after {
    width: 142px !important;
    left: 85% !important;
    transform: rotate(-27deg);
  }
  .chain-item-block.logistic.buyPost .chain-item-inner:after {
    width: 142px !important;
    left: 84% !important;
    top: 46%;
    transform: rotate(-28deg);
  }
  .chain-item-block.knowledge .chain-item-inner:after {
    width: 137px !important;
  }
}
@media screen and (width: 720px){
  .chain-item-block.garment .chain-item-inner:after,
  .chain-item-block.textiles .chain-item-inner:after{
    display: block;
    opacity: 1;
    visibility: visible;
  }
  .chain-item-block.yarn .chain-item-inner:after,
  .chain-item-block.design .chain-item-inner:after,
  .chain-item-block.knowledge .chain-item-inner:after{
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  .chain-item-block.garment .chain-item-inner:after,
  .chain-item-block.consultant .chain-item-inner:after,
  .chain-item-block.logistic.buyPost .chain-item-inner:after,
  .chain-item-block.textiles .chain-item-inner:after,
  .chain-item-inner:after {
    width: 197px !important;
    left: 77% !important;
    transform: rotate(-22deg);
    top: 50%;
  }

}
@media screen and (max-width: 639px){
	.full-pagecontent, .content-wrapper{
    padding-top: 180px;
  }
}
@media screen and (max-width: 601px){
  .chain-item-block.logistic.buyPost > h4{
    width: 147px;
  }
  .big-circleblock-contentbody{
    padding: 70px 0 0 0;
  }
  .big-circleblock-contentbody .big-circleblock:last-child{
    margin-right: 0;
  }
  .header-inbox {
    margin: 0 0px;
  }
  .full-pagecontent, .content-wrapper {
    padding-top: 160px;
  }
  .header-pagecontent{
    padding: 0 30px 0px 30px!important;
  }
  .chain-item-block.yarn .chain-item-inner:after,
  .chain-item-block.design .chain-item-inner:after,
  .chain-item-block.knowledge .chain-item-inner:after{
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  .chain-section{
    padding: 70px 0 40px 0;
  }
  .chain-item-block.textiles .chain-item-inner:after{
    display: block;
    opacity: 1;
    visibility: visible;
  }
  .chain-item-inner:after {
    width: 153px !important;
  }
  .chain-item-block.garment .chain-item-inner:after{
    width: 151px !important;
    display: block;
    opacity: 1;
    visibility: visible;
  }
  .chain-item-block.textiles .chain-item-inner:after {
    width: 145px!important;
    left: 86%!important;
    top: 45%!important;
    transform: rotate(-26deg);
  }
  .chain-item-block.design .chain-item-inner:after,
  .chain-item-block.knowledge .chain-item-inner:after {
    width: 155px!important;
    left: 84%!important;
    transform: rotate(-26deg);
  }

  .chain-item-block.logistic.buyPost .chain-item-inner:after {
    width: 158px !important;
    left: 81% !important;
  }
  .chain-item-block.consultant .chain-item-inner:after {
    width: 153px!important;
    left: 85%;
  }





}
@media screen and (width: 600px){
  .chain-item-block.logistic.buyPost > h4{
    width: 146.6px;
  }
}
@media screen and (width: 480px){
  .chain-item-block.logistic.buyPost > h4{
    width: 190px;
  }
  .chain-item-block.yarn .chain-item-inner:after,
  .chain-item-block.consultant .chain-item-inner:after,
  .chain-item-block.logistic.buyPost .chain-item-inner:after,
  .chain-item-block.knowledge .chain-item-inner:after{
    display: block;
    opacity: 1;
    visibility: visible;
  }
  .chain-item-block.yarn .chain-item-inner:after,
  .chain-item-block.consultant .chain-item-inner:after,
  .chain-item-block.logistic.buyPost .chain-item-inner:after,
  .chain-item-block.knowledge .chain-item-inner:after,
  .chain-item-inner:after {
    width: 189px !important;
    left: 86%!important;
    top: 45%;
    transform: rotate(-22deg);
  }
  .chain-item-block.logistic .chain-item-inner:after,
  .chain-item-block.garment .chain-item-inner:after,
  .chain-item-block.design .chain-item-inner:after,
  .chain-item-block.textiles .chain-item-inner:after,
  .chain-item-block.machine .chain-item-inner:after{
    display: none;
    opacity: 0;
    visibility: hidden;
  }

}
@media screen and (max-width: 480px){
  // to hide another white box shoed when click on hader 3 dots 
  // body > div:last-child{
  //   display: none;
  //   opacity: 0;
  //   visibility: hidden;
  // }
  
}
@media screen and (max-width: 425px){
  .chain-item-block.logistic.buyPost > h4{
    width: 162.5px;
  }
  .card-item-footer button{
	  font-size: 14px;
	}
  .header-signin-btn a,
	.main-menulist li a{
		font-size: 15px;
	}
  .chain-item-block h4,
  .big-circleblock-inner p a{
    font-size: 17px;
  }
  .admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-overview .dashboard-cart-item{
    margin: 0 0 20px 0px;
  }
	.header-pagecontent{
		padding: 0 30px;
	}
  .admin-dashboard-wrapper .main-layout .ant-menu.navigation-menu.ant-menu-light.ant-menu-root.ant-menu-vertical{
    margin-left: 20px;
  }
  .admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-footer,
  .admin-dashboard-wrapper .main-layout .content-wrapper .dashboard-section-middle{
    margin: 0;
  }
  
  .chain-item-block.logistic .chain-item-inner:after,
  .chain-item-block.garment .chain-item-inner:after,
  .chain-item-block.design .chain-item-inner:after,
  .chain-item-block.textiles .chain-item-inner:after,
  .chain-item-block.machine .chain-item-inner:after{
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  .header-pagecontent {
    padding: 0 20px 0px 20px !important;
  }
  .chain-item-block.knowledge .chain-item-inner:after,
  .chain-item-block.logistic.buyPost .chain-item-inner:after,
  .chain-item-block.yarn .chain-item-inner:after{
    display: block;
    opacity: 1;
    visibility: visible;
  }
  .chain-item-inner:after {
    width: 173px !important;
    left: 83%!important;
    transform: rotate(-25deg);
  }
  .chain-item-block.garment .chain-item-inner:after,
  .chain-item-block.consultant .chain-item-inner:after,
  .chain-item-block.design .chain-item-inner:after,
  .chain-item-block.yarn .chain-item-inner:after {
    width: 170px !important;
    left: 84%!important;
    transform: rotate(-24deg);
  }
  .chain-item-block.logistic.buyPost .chain-item-inner:after {
    width: 177px !important;
    left: 79% !important;
    transform: rotate(-26deg);
  }
	.chain-item-block.knowledge .chain-item-inner:after {
    width: 177px !important;
    left: 74% !important;
    transform: rotate(-25deg);
  }
}
@media screen and (max-width: 414px){
  .chain-item-block.logistic.buyPost > h4{
    width: 157px;
  }
  .chain-item-inner:after {
    width: 168px !important;
    left: 83% !important;
    transform: rotate(-25deg);
  }
  .chain-item-block.logistic .chain-item-inner:after, .chain-item-block.garment .chain-item-inner:after, .chain-item-block.consultant .chain-item-inner:after, .chain-item-block.design .chain-item-inner:after, .chain-item-block.yarn .chain-item-inner:after{
    width: 164px !important;
  }
  .chain-item-block.knowledge .chain-item-inner:after {
    width: 169px !important;
  }
}
@media screen and (max-width: 412px){
  .chain-item-block.logistic.buyPost > h4{
    width: 156px;
  }
}
@media screen and (max-width: 375px){
  .chain-item-block.logistic.buyPost > h4{
    width: 137.5px;
  }
  .chain-item-inner:after {
    width: 134px !important;
    left: 88% !important;
    transform: rotate(-25deg);
  }
  .chain-item-block.logistic .chain-item-inner:after, .chain-item-block.garment .chain-item-inner:after, .chain-item-block.consultant .chain-item-inner:after, .chain-item-block.design .chain-item-inner:after, .chain-item-block.yarn .chain-item-inner:after {
    width: 130px !important;
    left: 89%!important;
  }
  .chain-item-block.logistic.buyPost .chain-item-inner:after {
    width: 136px !important;
    left: 85% !important;
    transform: rotate(-26deg);
  }
  .chain-item-block.knowledge .chain-item-inner:after {
    width: 132px !important;
    left: 84%!important;
  }

}
@media screen and (max-width: 360px){
  .chain-item-block.logistic.buyPost > h4{
    width: 130px;
  }
  .chain-item-inner:after {
    width: 121px !important;
    left: 91% !important;
    transform: rotate(-25deg);
  }
  .chain-item-block.logistic .chain-item-inner:after, .chain-item-block.garment .chain-item-inner:after, .chain-item-block.consultant .chain-item-inner:after, .chain-item-block.design .chain-item-inner:after, .chain-item-block.yarn .chain-item-inner:after {
    width: 117px !important;
    left: 92% !important;
  }
  .chain-item-block.logistic.buyPost .chain-item-inner:after {
    width: 122px !important;
    left: 88% !important;
    transform: rotate(-26deg);
  }
  .chain-item-block.knowledge .chain-item-inner:after {
    width: 123px !important;
    left: 85% !important;
  }
}
@media screen and (max-width: 349px){
  .big-circleblock{
    margin-right: auto;
    margin-left: auto;
  }
  .big-circleblock-contentbody{
    display: block;
  }
}
@media screen and (max-width: 320px){
  .chain-item-block.logistic.buyPost > h4{
    width: 112.5px;
  }
  .big-circleblock-contentbody .big-circleblock:last-child {
    margin-right: auto;
  }
  .chain-section-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 20px 15px;
  }
  .chain-item-inner:after {
    width: 87px !important;
    left: 95% !important;
    transform: rotate(-25deg);
  }
  .chain-item-block.logistic .chain-item-inner:after, .chain-item-block.garment .chain-item-inner:after, .chain-item-block.consultant .chain-item-inner:after, .chain-item-block.design .chain-item-inner:after, .chain-item-block.yarn .chain-item-inner:after {
    width: 87px !important;
    left: 95% !important;
  }
  .chain-item-block.logistic.buyPost .chain-item-inner:after {
    width: 91px !important;
    left: 93% !important;
  }
  .chain-item-block.knowledge .chain-item-inner:after {
    width: 89px !important;
    left: 91% !important;
}



}
